/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ya hace tiempo que hable de lowfat, vuelvo a hablaros de él para explicaros una forma mas sencilla de instalarlo. Mediante una paquete .deb que podeis descargar aqui:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://s07.divshare.com/launch.php?f=1546938&s=36c"
  }, "Lowfat deb")), "\n", React.createElement(_components.p, null, "Lo instalamos por consola con el comando:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "$ dpkg -i lowfat_0.0.1_1-1_i386.deb"), "\n"), "\n", React.createElement(_components.p, null, "Y ya esta instalado, pero cuando lo intentamos ejecutar tenemos un problema, que no encuentra la imagenes de fondo del programa, para solucionarlo nos bajamos estas:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://s07.divshare.com/launch.php?f=1547063&s=450"
  }, "Lowfat-pixmaps")), "\n", React.createElement(_components.p, null, "la descomprimimos y ponemos la carpeta pixmaps en otra carpeta que crearemos para el lowfat, en mi caso he creado una .lowfat en mi carpeta personal."), "\n", React.createElement(_components.p, null, "Cuando lo queramos ejecutar tendremos que irnos a esta carpeta y desde alli ejecutarlo. También podemos hacer un script para ejecutrarlo directamente desde un icono. En mi caso será asi:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "#! /bin/sh"), "\n", React.createElement(_components.p, null, "cd /home/litox/.lowfat && lowfat"), "\n"), "\n", React.createElement(_components.p, null, "Luego lo guardamos como Lowfat.sh, le damos permisos de ejecución y ya tenemos listo nuestro lowfat, como ya me a pasado con otros programas este tampoco es compatible con Compiz y para ejecutarlo tengo que desactivar Compiz."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
